import React, {Component} from 'react';
import { withTranslate } from 'react-redux-multilingual'

import HeaderOne from './common/headers/header-one';
import FooterThree from "./common/footers/footer-three";
import AcceptCookies from "./common/accept-cookies";
import {ToastContainer} from "react-toastify";

class App extends Component {

    render() {
        return (
            <div>
                <HeaderOne logoName={'logo.png'}/>
                {this.props.children}
                <FooterThree logoName={'logo.png'}/>
                <ToastContainer/>
                <AcceptCookies/>
            </div>
        );
    }
}

export default withTranslate(App);
