import React, {Component} from 'react';
import {IntlActions} from 'react-redux-multilingual'
import Pace from 'react-pace-progress'
// Import custom components
import store from '../../../store';
import NavBar from "./common/navbar";
import CartContainer from "./../../../containers/CartContainer";
import {changeCurrency} from '../../../actions'
import {connect} from "react-redux";
import lightAffectImg from '../../../assets/images/lightaffect.png'

class HeaderOne extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    }
  }

  /*=====================
       Pre loader
       ==========================*/
  componentDidMount() {
    setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);

    this.setState({open: true});
  }

  componentWillMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    let number = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 300) {
      if (window.innerWidth < 576) {
        document.getElementById("sticky").classList.remove('fixed');
      } else
        document.getElementById("sticky").classList.add('fixed');
    } else {
      document.getElementById("sticky").classList.remove('fixed');
    }
  }

  changeLanguage(lang) {
    store.dispatch(IntlActions.setLocale(lang))
  }

  openNav() {
    var openmyslide = document.getElementById("mySidenav");
    if (openmyslide) {
      openmyslide.classList.add('open-side')
    }
  }

  load = () => {
    this.setState({isLoading: true});
    fetch().then(() => {
      // deal with data fetched
      this.setState({isLoading: false})
    })
  };

  render() {
    const isMobile = window.innerWidth <= 768;
    return (
      <div>
        <header id="sticky" className="sticky" style={{backgroundColor: '#0c0c0c'}}>
          {this.state.isLoading ? <Pace color="#27ae60"/> : null}
          <div className="mobile-fix-option"/>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="main-menu">
                  <div className="menu-left">
                    <div className="navbar">
                      <a href="/">
                        <img src={lightAffectImg} alt="LIGHT AFFECT" height={isMobile ? "34px": "50px"} width="auto" style={{ cursor: 'pointer'}}/>
                      </a>
                    </div>
                  </div>
                  <div>
                    <img src={"https://fs.lightaffect.am/download?filePath=/assets/webacht/free_usa-1717098956467.png"} width="auto" height={isMobile ? "34px": "80px"} style={{marginLeft: isMobile ? '-60px': ''}}/>
                  </div>
                  <div className="menu-right pull-right">
                    {/*Top Navigation Bar Component*/}
                    <NavBar/>

                    <div>
                      <div className="icon-nav">
                        <ul>
                          {/*Header Cart Component */}
                          <CartContainer/>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

      </div>
    )
  }
}

export default connect(null,
  {changeCurrency}
)(HeaderOne);
