import store from "../store";
import {updateLocation} from "../actions";

export function fetchLocation() {
    function getLocationFromCountry(country_name) {
        switch (country_name.toUpperCase()) {
            case "ARMENIA":
                return "ARMENIA";
            case "RUSSIA":
                return "RUSSIA";
            case "AUSTRIA":
            case "BELGIUM":
            case "CYPRUS":
            case "ESTONIA":
            case "FINLAND":
            case "FRANCE":
            case "GERMANY":
            case "GREECE":
            case "IRELAND":
            case "ITALY":
            case "LATVIA":
            case "LITHUANIA":
            case "LUXEMBOURG":
            case "MALTA":
            case "THE NETHERLANDS":
            case "PORTUGAL":
            case "SLOVAKIA":
            case "SLOVENIA":
            case "SPAIN":
                return "EUROPE";
            default:
                return "OTHER";
        }
    }
    fetch('http://ip-api.com/json')
        .then(response => response.json())
        .then(data => store.dispatch(updateLocation(getLocationFromCountry(data.country))))
        .catch(error => store.dispatch(updateLocation(getLocationFromCountry('OTHER'))));
}