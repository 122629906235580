import React, {Component} from 'react';
import '../common/index.scss';
import {connect} from "react-redux";
import ImageGallery from 'react-image-gallery';
// import custom Components
import RelatedProduct from "../common/related-product";
import Breadcrumb from "../common/breadcrumb";
import DetailsWithPrice from "./common/product/details-price";
import {addToCart, addToCartUnsafe, addToWishlist, fetchAllProducts} from '../../actions'
import {getBestSeller, getSimilarProducts} from "../../services";
import {getAllProducts as getAll} from "../services/api";
import store from "../../store";
import PageNotFound from "../pages/404";
import {fetchLocation} from "../../services/util";
import {Helmet} from "react-helmet";


class NoSideBar extends Component {

  constructor() {
    super();
    this.state = {
      nav1: null,
      nav2: null,
      loading: true
    };
  }

  componentDidMount() {
    const {productId} = this.props;
    getAll().then(data => {
      store.dispatch(fetchAllProducts(data));
      this.setState({
        nav1: this.slider1,
        nav2: this.slider2,
        item: data.find(el => el.id === productId),
      });
      setTimeout(function () {
        document.querySelector(".loader-wrapper").style = "display: none";
      }, 2000);
    });
  }


  render() {
    const {symbol, addToCart, addToCartUnsafe, addToWishlist, item, allProducts, location} = this.props;
    if (!location) {
      fetchLocation();
    }
    if (!item) {
      return (<PageNotFound/>);
    }
    let similarItems = [];
    let saleItems = [];
    if (item) {
      similarItems = getSimilarProducts(allProducts, item.category, item.subCategory, item.id);
      saleItems = getBestSeller(allProducts, item.id);
    }
    const finalPics = [];
    if (item.pictures) {
      item.pictures.map((item, index) => {
        if (item !== "" && item){
          const pic = {
            original: item,
            thumbnail: item
          };
          finalPics.push(pic)
        }
      });
    }

    return (
      <div>
        <Breadcrumb title={' Product / ' + item.name}/>
        <Helmet>
          <title>Light Affect</title>
          <meta name="description" content="Light Affect Product" />
          <meta property="og:title" content={item.name}/>
          <meta property="og:description" content={item.name}/>
          <meta property="og:image" itemProp="image" content={item.pictures[0]}/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content={"https://lightaffect.am"}/>
        </Helmet>
        {/*Section Start*/}
        {(item) ?
          <section>
            <div className="collection-wrapper">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 product-thumbnail">
                      {item.pictures &&
                        <ImageGallery items={finalPics} showIndex={true} />
                      }
                    </div>
                  <DetailsWithPrice symbol={symbol} item={item} navOne={this.state.nav1} addToCartClicked={addToCart}
                                    BuynowClicked={addToCartUnsafe} addToWishlistClicked={addToWishlist} location={location}/>

                  {similarItems.length > 2 && <RelatedProduct items={similarItems} text='Similar products'/>}
                  {saleItems.length > 2 && <RelatedProduct items={saleItems} text='Sale products'/>}
                </div>
              </div>
            </div>
          </section> : ''}
        {/*Section End*/}
        {/*<ToastContainer/>*/}

      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let productId = ownProps.match.params.id;
  return {
    item: state.data.allProducts ? state.data.allProducts.find(el => el.id === productId) : null,
    allProducts: state.data.allProducts,
    symbol: state.data.symbol,
    location: state.data.location,
    productId
  }
};

export default connect(mapStateToProps, {addToCart, addToCartUnsafe, addToWishlist})(NoSideBar);
