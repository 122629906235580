import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

import Breadcrumb from "../common/breadcrumb";
import {getCartTotal, getCartTotalWeight} from "../../services";
import {decrementQty, incrementQty, removeFromCart} from '../../actions'
import {getAllCountries} from "../services/api";

class cartComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      shippingPrice: 0,
      temp: false
    };

  }

  componentDidMount() {
    getAllCountries()
      .then(data => {
        this.setState({
          countries: data
        })
      })
  }

  render() {
    const {cartItems, symbol, total, totalWeight, location} = this.props;

    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>Light Affect | Cart </title>
          <meta name="description" content="Light Affect"/>
        </Helmet>
        {/*SEO Support End */}

        <Breadcrumb title={'Cart'}/>

        {cartItems.length > 0 ?
          <section className="cart-section section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <table className="table cart-table table-responsive-xs">
                    <thead>
                    <tr className="table-head">
                      <th scope="col">image</th>
                      <th scope="col">product name</th>
                      <th scope="col">price</th>
                      <th scope="col">quantity</th>
                      <th scope="col">action</th>
                      <th scope="col">total</th>
                    </tr>
                    </thead>
                    {cartItems.map((item, index) => {
                      return (
                        <tbody key={index}>
                        <tr>
                          <td>
                            <Link to={{
                              pathname: `${process.env.PUBLIC_URL}/product/${item.id}`,
                            }}>
                              <img width="90px" src={item.variants ?
                                item.variants[0].images
                                : item.pictures[0]} alt=""/>
                            </Link>
                          </td>
                          <td><Link to={{
                            pathname: `${process.env.PUBLIC_URL}/product/${item.id}`,
                          }}><b>{item.name}</b> | Size: <b>{item.size}</b></Link>
                            <div className="mobile-cart-content row">
                              <div className="col-xs-3">
                                <div className="qty-box">
                                  <div className="input-group">
                                    <input type="number" name="quantity"
                                           className="form-control input-number" defaultValue={item.qty}
                                           min={1} max={ this.getMaxCountOfThisItem(item)}
                                           onChange={(e) => this.handleChangeCount(e, item)}/>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xs-3">
                                <h2 className="td-color">
                                  {symbol}{item.isSale ? item.saleMap[location] : item.priceMap[location]}
                                </h2>
                              </div>
                              <div className="col-xs-3">
                                <h2 className="td-color">
                                  <a href="#" className="icon" onClick={() => this.props.removeFromCart(item)}>
                                    <i className="icon-trash"/>
                                  </a>
                                </h2>
                              </div>
                            </div>
                          </td>
                          <td>
                            <h2>{symbol}{item.isSale ? item.saleMap[location] : item.priceMap[location]}</h2>
                          </td>
                          <td>
                            <div className="qty-box">
                              <div className="input-group">
                                                            <span className="input-group-prepend">
                                                                <button type="button"
                                                                        className="btn quantity-left-minus"
                                                                        onClick={() => this.props.incrementQty(item, -1, item.size)}
                                                                        disabled={(item.qty === 1)}
                                                                        data-type="minus" data-field="">
                                                                 <i className="fa fa-angle-left"/>
                                                                </button>
                                                            </span>
                                <input type="text" name="quantity" value={item.qty} readOnly={true}
                                       className="form-control input-number"/>

                                <span className="input-group-prepend">
                                                            <button className="btn quantity-right-plus"
                                                                    onClick={() => this.props.incrementQty(item, 1, item.size)}
                                                                    data-type="plus"
                                                                    disabled={(item.qty >= item.stock)}>
                                                            <i className="fa fa-angle-right"/>
                                                            </button>
                                                           </span>
                              </div>
                            </div>
                            {(item.qty >= item.stock) ? 'out of Stock' : ''}
                          </td>
                          <td>
                            <i style={{cursor: 'pointer'}} className="icon-trash"
                               onClick={() => this.props.removeFromCart(item, item.size)}/>
                          </td>
                          <td>
                            <h2 className="td-color">
                              {symbol}{item.qty * (item.isSale ? item.saleMap[location] : item.priceMap[location])}
                            </h2>
                          </td>
                        </tr>
                        </tbody>)
                    })}
                  </table>
                  <table className="table cart-table table-responsive-md">
                    {totalWeight > 2000 &&
                    <tr>
                      <td style={{textAlign: 'center'}}>
                        Your shipping weight is more than 2kg and will be delivered
                        with {Math.ceil(totalWeight / 2000)} different packages.
                      </td>
                    </tr>
                    }
                    <tfoot>
                    <tr>
                      <td>total price without shipping:</td>
                      <td><h2>{symbol} {total} </h2></td>
                    </tr>
                    </tfoot>
                  </table>
                  <div style={{textAlign: 'right', padding: '10px 6% 0 0', fontSize: '14px'}}>
                    Proceed to checkout to select shipping country
                  </div>
                </div>
              </div>
              <div className="row cart-buttons">
                <div className="col-6">
                  <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">continue
                    shopping</Link>
                </div>
                <div className="col-6">
                  <Link to={`${process.env.PUBLIC_URL}/checkout`} className="btn btn-solid">check out</Link>
                </div>
              </div>
            </div>
          </section>
          :
          <section className="cart-section section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div>
                    <div className="col-sm-12 empty-cart-cls text-center">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`}
                           className="img-fluid mb-4" alt=""/>
                      <h3>
                        <strong>Your Cart is Empty</strong>
                      </h3>
                      <h4>Explore more items.</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
      </div>
    )
  }

  getMaxCountOfThisItem(item) {
    let count = 0;
    item.sizeAndCountList.forEach(sizeAndCount => {
      if (item.size === sizeAndCount.size) {
        count = sizeAndCount.count;
      }
    });
    return count;
  }

  handleChangeCount(event, item) {
    if (event.target.value  === "") {
      return;
    }
    if (event.target.value < 1 || event.target.value > this.getMaxCountOfThisItem(item)) {
      event.target.value = 1;
    }
    this.props.incrementQty(item, event.target.value - item.qty, item.size)
    const {temp} = this.state;
    this.setState({temp: !temp})
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.cartList.cart,
  symbol: state.data.symbol,
  location: state.data.location,
  total: getCartTotal(state.cartList.cart, state.data.location),
  totalWeight: getCartTotalWeight(state.cartList.cart)
});

export default connect(
  mapStateToProps,
  {removeFromCart, incrementQty, decrementQty}
)(cartComponent)
