import React, {Component} from 'react';
import '../../assets/loading-image.css';

class LoadingImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.imageLoaded = this.imageLoaded.bind(this);
    this.ref = React.createRef();
  }

  imageLoaded() {
    this.setState({
      loading: false
    })
  }

  componentDidMount() {
   this.checkImageLoaded();
  }

  checkImageLoaded(){
    const img = this.ref.current;

    if (img && img.complete) {
      this.imageLoaded();
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.src !== this.props.src) {
      this.setState({
        loading: true
      });
      this.checkImageLoaded();
    }
  }


  render() {
    const {src, alt, className} = this.props;
    const {loading} = this.state;

    return (
      <div className={'loading-image-container'}>
        <div className={loading ? 'loader-wrapper' : 'loader-none'}>
          <img src="https://fs.lightaffect.am/download?filePath=/assets/webacht/giphy_loading_-1582705039079.gif"  className="loader-image" />
        </div>
        <img src={src} alt={alt} ref={this.ref} className={className} onLoad={this.imageLoaded}/>
      </div>
    );
  }
}

export default LoadingImage;
