import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Modal from 'react-responsive-modal';
import LoadingImage from '../../utils/LoadingImage';

class ProductItem extends Component {

  constructor(props) {
    super(props)

    this.state = {
      open: false,
      stock: 'InStock',
      quantity: 1,
      image: ''
    }
  }

  onClickHandle(img) {
    this.setState({image: img});
  }

  onOpenModal = () => {
    const {product} = this.props;
    const selectedSize = product.sizeAndCountList && product.sizeAndCountList.length !== 0 ? product.sizeAndCountList[0].size : 'One Size';
    const selectedSizeCount = product.sizeAndCountList && product.sizeAndCountList.length !== 0 ? product.sizeAndCountList[0].count : 0;

    this.setState({
      selectedSize,
      selectedSizeCount,
      open: true
    });
  };
  handleSizeClick = (item) => {
    const quantity = item.count >= this.state.quantity ? this.state.quantity : 1;
    this.setState({
      selectedSize: item.size,
      selectedSizeCount: item.count,
      quantity,
    });
  };

  onCloseModal = () => {
    this.setState({open: false});
  };

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({
        quantity: this.state.quantity - 1
      });
    }
  };

  plusQty = () => {
    if (this.state.selectedSizeCount > this.state.quantity) {
      this.setState({quantity: this.state.quantity + 1})
    }
  };
  changeQty = (e) => {
    this.setState({quantity: parseInt(e.target.value)})
  };

  render() {
    const {product, symbol, onAddToCartClicked, location} = this.props;
    const {selectedSizeCount} = this.state;
    const itemsCountLeftText = selectedSizeCount > 0
      ? `Only ${selectedSizeCount} left in stock`
      : 'Out Of Stock';
    const isAddToCartButtonDisabled = selectedSizeCount < 1;

    return (
      <div className="product-box collection-banner">
        <div className="img-wrapper">
          <div className="lable-block">
            {product.isNew ? <span className="lable3" style={{zIndex: '5'}}>new</span> : ''}
            {product.isSale ? <span className="lable4" style={{color: 'white', zIndex: '5'}}>on sale</span> : ''}

          </div>
          <div className="front">
            <Link to={{
              pathname: `${process.env.PUBLIC_URL}/product/${product.id}`,
            }}><LoadingImage src={product.variants ?
              this.state.image ? this.state.image : product.variants[0].images
              : product.pictures[0]} alt="" className="img-fluid"/></Link>
          </div>
          <div className="cart-info cart-wrap">
            <a href="javascript:void(0)" data-toggle="modal"
               data-target="#quick-view"
               title="Quick View"
               onClick={this.onOpenModal}><i className="fa fa-search" aria-hidden="true"/></a>
          </div>
        </div>
        <div className="product-detail">
          <div>
            <Link to={{
              pathname: `${process.env.PUBLIC_URL}/product/${product.id}`,
            }}>
              <h6>{product.name}</h6>
            </Link>
            {
              product.isSale ?
                  <h4>{symbol}{product.saleMap[location]}
                    <del><span className="money">{symbol}{product.priceMap[location]}</span></del>
                  </h4>
                  :
                  <h4>{symbol}{product.priceMap[location]}</h4>
            }
          </div>
        </div>
        <Modal open={this.state.open} onClose={this.onCloseModal} center>
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content quick-view-modal">
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6  col-xs-12">
                    <div className="quick-view-img">
                      <LoadingImage src={product.variants ?
                        this.state.image ? this.state.image : product.variants[0].images
                        : product.pictures[0]} alt="" className="img-fluid"/>
                    </div>
                  </div>
                  <div className="col-lg-6 rtl-text">
                    <div className="product-right">
                      <h2> {product.name} </h2>
                      {
                        product.isSale ?
                            <h4>{symbol}{product.saleMap[location]}
                              <del><span className="money">{symbol}{product.priceMap[location]}</span></del>
                            </h4>
                            :
                            <h4>{symbol}{product.priceMap[location]}</h4>
                      }
                      {
                        product.description && <div className="border-product">
                          <h6 className="product-title">product description</h6>
                          <p style={{whiteSpace: ' pre-wrap'}}>{product.description}</p>
                        </div>
                      }
                      {
                        product.details && <div className="border-product">
                          <h6 className="product-title">product details</h6>
                          <p style={{whiteSpace: ' pre-wrap'}}>{product.details}</p>
                        </div>
                      }
                      <div className="product-description border-product">
                        <h6 className="product-title">Size</h6>
                        {!this.isSizeBig(product.sizeAndCountList) ?
                          <div className="size-box">
                            <ul>
                              {product.sizeAndCountList.map((sizeAndCount, i) => {
                                return <li key={i}
                                           style={sizeAndCount.size === this.state.selectedSize ? {
                                             border: '1px solid black',
                                             padding: '5px'
                                           } : {}}
                                           onClick={() => this.handleSizeClick(sizeAndCount)}>
                                  {sizeAndCount.size}
                                </li>
                              })}
                            </ul>
                            <h6 className="product-title" style={{paddingTop: '10px', color: '#B12704'}}>
                              {itemsCountLeftText}
                            </h6>
                          </div> :
                          <div className="size-box">
                            <ul>
                              {product.sizeAndCountList.map((sizeAndCount, i) => {
                                return <li key={i}
                                           style={sizeAndCount.size === this.state.selectedSize ? {
                                             border: '1px solid black',
                                             padding: '5px',
                                             width: '100px',
                                             borderRadius: '0%',
                                           } : {
                                             width: '100px',
                                             borderRadius: '0%',
                                             padding: '5px',
                                           }}
                                           onClick={() => this.handleSizeClick(sizeAndCount)}>
                                  {sizeAndCount.size}
                                </li>
                              })}
                            </ul>
                            <h6 className="product-title" style={{paddingTop: '10px', color: '#B12704'}}>
                              {itemsCountLeftText}
                            </h6>
                          </div>
                        }
                        {!isAddToCartButtonDisabled ?
                          <div>
                            <h6 className="product-title">quantity</h6>
                            <div className="qty-box">
                              <div className="input-group">
                              <span className="input-group-prepend">
                                     <button type="button"
                                             className="btn quantity-left-minus"
                                             onClick={this.minusQty} data-type="minus"
                                             data-field="">
                                                    <i className="fa fa-angle-left"/>
                                     </button>
                                     </span>
                                <input type="text" name="quantity"
                                       value={this.state.quantity}
                                       onChange={this.changeQty}
                                       className="form-control input-number"/>
                                <span className="input-group-prepend">
                                     <button type="button"
                                             className="btn quantity-right-plus"
                                             onClick={this.plusQty} data-type="plus"
                                             data-field="">
                                          <i className="fa fa-angle-right"/>
                                     </button>
                            </span>
                              </div>
                            </div>
                          </div>
                          :
                          <div/>
                        }

                      </div>
                      <div className="product-buttons">
                        <button className="btn btn-solid" disabled={isAddToCartButtonDisabled}
                                onClick={() => onAddToCartClicked(product, this.state.quantity, this.state.selectedSize)}>add
                          to cart
                        </button>
                        <Link to={{
                          pathname: `${process.env.PUBLIC_URL}/product/${product.id}`,
                        }}
                              className="btn btn-solid">view detail</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }

  isSizeBig(sizeAndCountList) {
    let isBig = false;
    if (sizeAndCountList) {
      sizeAndCountList.forEach(it => {
        if (it.size.length > 2) {
          isBig = true;
        }
      })
    }
    return isBig;
  };
}

export default ProductItem;
