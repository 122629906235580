import React from 'react';
import {Link} from 'react-router-dom'

const CartHeader = ({item, total, symbol, removeFromCart, location}) => (
  <li>
    <div className="media"
         style={{
           borderBottom: '1px solid rgb(203, 203, 203)',
           paddingRight: '10%'
         }}>
      <Link to={{
        pathname: `${process.env.PUBLIC_URL}/product/${item.id}`,
      }}><img alt="" width="80px" style={{padding: '7px'}} className="mr-3"
              src={item.pictures ? item.pictures[0] : ''}/></Link>
      <div className="media-body">
        <Link to={{
          pathname: `${process.env.PUBLIC_URL}/product/${item.id}`,
        }}><h4>{item.name}</h4></Link>
        <h4><span><b>Size</b>: {item.size}</span></h4>
          <h4>
              <span>{item.qty} x {symbol} {item.isSale ? item.saleMap[location] : item.priceMap[location]}</span>
          </h4>
      </div>
    </div>
    <div className="close-circle">
      <a href={null} onClick={removeFromCart}><i className="fa fa-trash" aria-hidden="true"/></a>
    </div>
  </li>
)


export default CartHeader;
