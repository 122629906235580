import React, {Component} from 'react';
import '../common/index.scss';
// import custom Components
import ProductListing from './common/product-listing'
import Breadcrumb from "../common/breadcrumb";
import FilterBar from "./common/filter-bar";
import {getAllProductsAndFilter} from '../../actions'
import {getAllProducts as getAll} from "../services/api";
import store from "../../store";
import {KidsSubCategoryReverseMap, MenSubCategoryReverseMap, WomenSubCategoryReverseMap} from "../config";


class CollectionNoSideBar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      layoutColumns: 3,
      showOnlySales: false,
      isLoading: true
    };
  }

  state = {
    layoutColumns: 3
  };

  LayoutViewClicked(colums) {
    this.setState({
      layoutColumns: colums
    })
  }

  componentDidMount() {
    let isSale = false;
    if (this.props.sale) {
      isSale = true;
    }
    getAll().then(data => {
      let category = this.props.category;
      let subCategory = this.props.match.params.subCategory;
      if (category) {
        category = category.toUpperCase();
      }
      if (subCategory) {
        subCategory = subCategory.toLowerCase();
      }
      store.dispatch(getAllProductsAndFilter(data, category, category && subCategory ?
        category === 'MEN' ?
          MenSubCategoryReverseMap[subCategory] :
          category === 'WOMEN' ?
            WomenSubCategoryReverseMap[subCategory] :
            KidsSubCategoryReverseMap[subCategory] :
        '', isSale));
      if(isSale) {
        this.setState({
          showOnlySales: isSale
        })
      }
      setTimeout(function () {
        this.setState({
          isLoading: false
        })
      }.bind(this), 100);
    });
  }

  render() {
    const subCategory = this.props.match.params.subCategory;
    const category = this.props.category;
    const {showOnlySales} = this.state;
    return (
      <div>
        <Breadcrumb title={'Shop'}/>

        {/*Section Start*/}
        {this.state.isLoading ?
            <section className="section-b-space" style={{width: "100%", height: "100%", display: "flex",justifyContent: "center", alignItems: "center"}}>
              <img src="https://fs.lightaffect.am/download?filePath=/assets/webacht/giphy_loading_-1582705039079.gif" alt={"Loading"}/>
            </section> :
            <section className="section-b-space">
              <div className="collection-wrapper">
                <div className="container">
                  <div className="row">
                    <div className="collection-content col">
                      <div className="page-main-content">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="collection-product-wrapper">
                                <div className="product-top-filter">
                                  <div className="container-fluid p-0">
                                    <div className="row">
                                      <div className="col-12">
                                        <FilterBar category={category}
                                                   subCategory={subCategory}
                                                   onLayoutViewClicked={(colmuns) => this.LayoutViewClicked(colmuns)}
                                                   showOnlySales={showOnlySales}/>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="product-wrapper-grid">
                                  <div className="container-fluid">
                                    <div className="row">
                                      <ProductListing colSize={this.state.layoutColumns}
                                                      category={category}
                                                      subCategory={subCategory}
                                                      showOnlySales={showOnlySales}/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        }
        {/*Section End*/}

      </div>
    )
  }
}

export default CollectionNoSideBar;
