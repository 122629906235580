/**
 * Mocking client-server processing
 */

const TIMEOUT = 100;


export default {
  getProducts: (cb, timeout) => setTimeout(() => cb(), timeout || TIMEOUT),
  buyProducts: (payload, cb, timeout) => setTimeout(() => cb(), timeout || TIMEOUT)
}
