import {
    CHANGE_BY_CATEGORY_AND_SUBCATEGORY,
    CHANGE_CURRENCY,
    CHANGE_LOCATION,
    RECEIVE_PRODUCTS
} from "../constants/ActionTypes";


const initialState = {
    products: [],
    symbol: '$',
    product_details: []
};

const productReducer = (state = initialState, action) => {
    function getSymbol(location) {
        switch (location) {
            case "ARMENIA":
                return "֏";
            case "RUSSIA":
                return "₽";
            case "EUROPE":
                return "€";
            case "OTHER":
            default:
                return "$";
        }
    }

    switch (action.type) {
        case RECEIVE_PRODUCTS:
            return {
                ...state,
                allProducts: action.products,
                products: action.products,
            };
        case CHANGE_BY_CATEGORY_AND_SUBCATEGORY:
            let filtered;
            if (action.isSale) {
                filtered = state.allProducts.filter(product => product.isSale === true);
                filtered.sort((product1, product2) => {
                    return product1.category < product2.category ? 1 : -1;
                })
            } else {
                filtered = state.allProducts ? state.allProducts.filter(product => product.category === action.category && (action.subCategory && action.subCategory !== '' ? product.subCategory === action.subCategory : true)) : state.products;
            }

            return {
                ...state,
                products: filtered
            };
        case CHANGE_CURRENCY:
            return {
                ...state,
                symbol: action.symbol
            };
        case CHANGE_LOCATION:
            return {
                ...state,
                symbol: getSymbol(action.location),
                location: action.location
            };
        default:
            return state;
    }
};
export default productReducer;
