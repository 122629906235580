import React, {Component} from 'react';
import {connect} from 'react-redux';

import {addToCart, addToCompare, addToWishlist} from "../../actions";
import ProductItem from '../layouts/common/product-item';


class RelatedProduct extends Component {
  render() {
    const {text, items, symbol, addToCart, location} = this.props;
    let titleName = text;
    if (!text) {
      titleName = 'Similar products';
    }

    return (
      <section className="section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-12 product-related">
              <h2>{titleName}</h2>
            </div>
          </div>
          <div className="row search-product">
            {items.slice(0, 6).map((product, index) =>
              <div key={index} className="col-xl-2 col-md-4 col-sm-6">
                <ProductItem product={product} symbol={symbol} location={location}
                             onAddToCartClicked={() => addToCart(product, 1)} key={index}/>
              </div>)
            }
          </div>
        </div>
      </section>
    )
  }
}

function mapStateToProps(state) {
  return {
    symbol: state.data.symbol,
    location: state.data.location,
  }
}

export default connect(mapStateToProps, {addToCart, addToWishlist, addToCompare})(RelatedProduct);
