import React, {Component} from 'react';

import {SlideUpDown} from "../../../services/script"
import {Link} from "react-router-dom";

class FooterThree extends Component {

  componentDidMount() {
    var contentwidth = window.innerWidth;
    if ((contentwidth) < 750) {
      SlideUpDown('footer-title');
    } else {
      var elems = document.querySelectorAll(".footer-title");
      [].forEach.call(elems, function (elemt) {
        let el = elemt.nextElementSibling;
        el.style = "display: block";
      });
    }
  }


  render() {

    return (
      <footer className="footer-light">
        <div className="light-layout">
          <div className="container">
            <section className="small-section border-section border-top-0">
            </section>
          </div>
        </div>
        <div className="light-layout">
          <div className="container">
            <section className="section-b-space light-layout">
              <div className="row footer-theme footer-theme2 partition-f">
                <div className="col offset-xl-1">
                  <div className="sub-title">
                    <div className="footer-title">
                      <h4>Shop</h4>
                    </div>
                    <div className="footer-contant">
                      <ul>
                        <li><Link to={`${process.env.PUBLIC_URL}/about-us`}>About Us</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/terms-conditions`}>Terms And Conditions</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/privacy-policy`}>Privacy Policy</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 subscribe-wrapper">
                  <div className="subscribe-block">
                    <h2 style={{color: 'black'}}>newsletter</h2>
                    <form>
                      <div className="form-group">
                        <input type="text" className="form-control"
                               id="exampleFormControlInput3" placeholder="Enter your email"/>
                        <button type="submit" className="btn btn-solid">subscribe</button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="footer-title">
                    <a href="/contact">
                      <h4 style={{color: 'black'}}>find us</h4>
                    </a>
                  </div>
                  <div className="footer-contant">
                    <ul className="contact-details" style={{textAlign: 'start'}}>
                      <li>
                        <p><i className="fa fa-map-marker" style={{marginRight: '5px'}}/> Dalma Garden Mall</p>
                        <p><i className="fa fa-map-marker" style={{marginRight: '5px'}}/> Tashir Street Shopping Gallery</p>
                      </li>
                      <li><i className="fa fa-phone" style={{marginRight: '5px'}}/>+37495098015</li>
                      <li><i className="fa fa-phone" style={{marginRight: '5px'}}/>+37444098014</li>
                      <li><i className="fa fa-envelope-o" style={{marginRight: '5px'}}/>
                        Email Us: <a href="#">customer.service@lightaffect.am</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="sub-footer lighter-subfooter" style={{borderTop: '1px solid #38352f'}}>
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-md-6 col-sm-12">
                <div className="footer-end">
                  <p><i className="fa fa-copyright" aria-hidden="true"/> Light Affect</p>
                </div>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12">
                <div className="payment-card-bottom">
                  <ul>
                    <li>
                      <a href={'https://www.facebook.com/lightaffect/'}
                         style={{color: 'black'}} target="_blank"
                         rel="noopener noreferrer">
                        <i
                          className="fa fa-facebook"
                          aria-hidden="true"/>
                      </a>
                    </li>
                    <li>
                      <a href={'https://www.instagram.com/light_affect'}
                         style={{color: 'black'}}
                         target="_blank"
                         rel="noopener noreferrer">
                        <i
                          className="fa fa-instagram" aria-hidden="true"/>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default FooterThree;
