import shop from '../api/shop'
import * as types from '../constants/ActionTypes'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const fetchProductsBegin = () => ({
  type: types.FETCH_PRODUCTS_BEGIN
});


export const receiveProducts = products => ({
  type: types.RECEIVE_PRODUCTS,
  products
});
export const changeCategoryAndSubCategory = (category, subCategory, isSale) => ({
  type: types.CHANGE_BY_CATEGORY_AND_SUBCATEGORY,
  category,
  subCategory,
  isSale
});

export const fetchAllProducts = (products) => dispatch => {
  shop.getProducts(() => {
    dispatch(receiveProducts(products));
    return products;
  })
};

export const getAllProductsAndFilter = (products, category, subCategory, isSale = false) => dispatch => {
  shop.getProducts(() => {
    dispatch(receiveProducts(products));
    dispatch(changeCategoryAndSubCategory(category, subCategory, isSale));
    return products;
  })
};

export const filterProducts = (category, subCategory, isSale= false) => dispatch => {
  dispatch(changeCategoryAndSubCategory(category, subCategory, isSale));
};


export const addToCart = (product, qty, size) => (dispatch) => {
  dispatch(addToCartUnsafe(product, qty, size))
};

export const addToCartAndRemoveWishlist = (product, qty) => (dispatch) => {
  dispatch(addToCartUnsafe(product, qty));
  dispatch(removeFromWishlist(product));
}

export const addToCartUnsafe = (product, qty, size) => ({
  type: types.ADD_TO_CART,
  product,
  qty,
  size
});
export const clearCartProducts = () => ({
  type: types.CLEAR_CART
});
export const removeFromCart = (product_id, size) => (dispatch) => {
  dispatch({
    type: types.REMOVE_FROM_CART,
    size,
    product_id
  })
};
export const clearCart = () => (dispatch) => {
  dispatch(clearCartProducts())
};
export const incrementQty = (product, qty, size) => (dispatch) => {
  dispatch(addToCartUnsafe(product, qty, size))

}
export const decrementQty = (productId, size) => (dispatch) => {
  toast.warn("Item Decrement Qty to Cart");

  dispatch({
    type: types.DECREMENT_QTY,
    size,
    productId
  })
};


//it seems that I should probably use this as the basis for "Wishlist"
export const addToWishlist = (product) => (dispatch) => {
  toast.success("Item Added to Wishlist");
  dispatch(addToWishlistUnsafe(product))

}
export const addToWishlistUnsafe = (product) => ({
  type: types.ADD_TO_WISHLIST,
  product
});
export const removeFromWishlist = product_id => (dispatch) => {
  toast.error("Item Removed from Wishlist");
  dispatch({
    type: types.REMOVE_FROM_WISHLIST,
    product_id
  })
};


//Compare Products
export const addToCompare = (product) => (dispatch) => {
  toast.success("Item Added to Compare");
  dispatch(addToCompareUnsafe(product))

}
export const addToCompareUnsafe = (product) => ({
  type: types.ADD_TO_COMPARE,
  product
});
export const removeFromCompare = product_id => ({
  type: types.REMOVE_FROM_COMPARE,
  product_id
});


// Filters
export const filterBrand = (brand) => ({
  type: types.FILTER_BRAND,
  brand
});
export const filterColor = (color) => ({
  type: types.FILTER_COLOR,
  color
});
export const filterPrice = (value) => ({
  type: types.FILTER_PRICE,
  value
});
export const filterSort = (sort_by) => ({
  type: types.SORT_BY,
  sort_by
});


// Currency
export const changeCurrency = (symbol) => ({
  type: types.CHANGE_CURRENCY,
  symbol
});

export const updateLocation = (location) => (dispatch) => {
  dispatch({
    type: types.CHANGE_LOCATION,
    location
  })
};



