import React, {Component} from 'react';
import Modal from 'react-responsive-modal';


class DetailsWithPrice extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      quantity: 1,
      stock: 'InStock',
      nav3: null
    }
  }

  handleSizeClick = (item) => {
    const quantity = item.count >= this.state.quantity ? this.state.quantity : 1;
    this.setState({
      selectedSize: item.size,
      selectedSizeCount: item.count,
      quantity,
    });
  };

  onOpenModal = () => {
    this.setState({open: true});
  };

  onCloseModal = () => {
    this.setState({open: false});
  };

  componentWillReceiveProps(nextProps, nextContext) {
    const {item} = nextProps;
    this.setState({
      sizeAndCountList: item.sizeAndCountList,
      selectedSize: item.sizeAndCountList && item.sizeAndCountList.length !== 0 ? item.sizeAndCountList[0].size : 'One Size',
      selectedSizeCount: item.sizeAndCountList && item.sizeAndCountList.length !== 0 ? item.sizeAndCountList[0].count : 0,
    });
  }

  componentDidMount() {
    if(this.props.item) {
      this.setState({
        nav3: this.slider3,
        sizeAndCountList: this.props.item.sizeAndCountList,
        selectedSize: this.props.item.sizeAndCountList && this.props.item.sizeAndCountList.length !== 0 ? this.props.item.sizeAndCountList[0].size : 'One Size',
        selectedSizeCount: this.props.item.sizeAndCountList && this.props.item.sizeAndCountList.length !== 0 ? this.props.item.sizeAndCountList[0].count : 0,
      });
    }

  }

  minusQty = () => {
    if (this.state.quantity > 1) {
      this.setState({
        quantity: this.state.quantity - 1
      });
    }
  };

  plusQty = () => {
    if (this.state.selectedSizeCount > this.state.quantity) {
      this.setState({quantity: this.state.quantity + 1})
    }
  };
  changeQty = (e) => {
    this.setState({quantity: parseInt(e.target.value)})
  }

  render() {
    const {symbol, item, addToCartClicked, location} = this.props;
    const {selectedSizeCount} = this.state;
    const itemsCountLeftText = selectedSizeCount > 0
      ? `Only ${selectedSizeCount} left in stock`
      : 'Out Of Stock';

    const isAddToCartButtonDisabled = selectedSizeCount < 1;

    return (
      <div className="col-lg-6 rtl-text">
        <div className="product-right">
          <h2> {item.name} </h2>
          {item.isSale ?
            <div>
              <h4>
                <del>{symbol}{ item.priceMap[location]}</del>
              </h4>
              <h3>{symbol}{item.saleMap[location]} </h3>
            </div>
            :
            <h4>
              {symbol}{item.priceMap[location]}
            </h4>
          }
          <div className="product-description border-product">
            <h6 className="product-title">Select Size</h6>
            {this.isSizeBig(item.sizeAndCountList) ?
              <div className="size-box">
                <ul>
                  {item.sizeAndCountList.map((sizeAndCount, i) => {
                    return <li key={i}
                               style={sizeAndCount.size === this.state.selectedSize ? {
                                 border: '1px solid black',
                                 padding: '5px',
                                 borderRadius: '0%',
                                 width: '100px',
                               } : {
                                 borderRadius: '0%',
                                 width: '100px',
                                 padding: '5px'
                               }}
                               onClick={() => this.handleSizeClick(sizeAndCount)}>
                      {sizeAndCount.size}
                    </li>
                  })}
                </ul>
                <h6 className="product-title" style={{paddingTop: '10px', color: '#B12704'}}>
                  {itemsCountLeftText}
                </h6>
              </div> :
              <div className="size-box">
                <ul>
                  {item.sizeAndCountList.map((sizeAndCount, i) => {
                    return <li key={i}
                               style={sizeAndCount.size === this.state.selectedSize ? {
                                 border: '1px solid black',
                                 padding: '5px',
                               } : {}}
                               onClick={() => this.handleSizeClick(sizeAndCount)}>
                      {sizeAndCount.size}
                    </li>
                  })}
                </ul>
                <h6 className="product-title" style={{paddingTop: '10px', color: '#B12704'}}>
                  {itemsCountLeftText}
                </h6>
              </div>
            }
            {!isAddToCartButtonDisabled ?
              <div>
                <h6 className="product-title">quantity</h6>
                <div className="qty-box">
                  <div className="input-group">
                                  <span className="input-group-prepend">
                                    <button type="button" className="btn quantity-left-minus" onClick={this.minusQty}
                                            data-type="minus" data-field="">
                                     <i className="fa fa-angle-left"/>
                                    </button>
                                  </span>
                    <input type="text" name="quantity" value={this.state.quantity} onChange={this.changeQty}
                           className="form-control input-number"/>
                    <span className="input-group-prepend">
                                <button type="button" className="btn quantity-right-plus" onClick={this.plusQty}
                                        data-type="plus" data-field="">
                                <i className="fa fa-angle-right"/>
                                </button>
                               </span>
                  </div>
                </div>
              </div>
              :
              <div/>
            }

          </div>
          <div className="product-buttons">
            <button className="btn btn-solid" disabled={isAddToCartButtonDisabled}
                    onClick={() => addToCartClicked(item, this.state.quantity, this.state.selectedSize)}>add to cart
            </button>
          </div>
          {
            item.description &&
            <div className="border-product">
              <h6 className="product-title">product description</h6>
              <p style={{whiteSpace: ' pre-wrap'}}>{item.description}</p>
            </div>
          }
          {
            item.details &&
            <div className="border-product">
              <h6 className="product-title">product details</h6>
              <p style={{whiteSpace: ' pre-wrap'}}>{item.details}</p>
            </div>
          }
        </div>
        <Modal open={this.state.open} onClose={this.onCloseModal} center>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Sheer Straight Kurta</h5>
              </div>
              <div className="modal-body">
                <img src={`${process.env.PUBLIC_URL}/assets/images/size-chart.jpg`} alt="" className="img-fluid"/>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }

  isSizeBig(sizeAndCountList) {
    let isBig = false;
    if (sizeAndCountList) {
      sizeAndCountList.forEach(it => {
        if (it.size.length > 2) {
          isBig = true;
        }
      })
    }
    return isBig;
  };
}


export default DetailsWithPrice;
