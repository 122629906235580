import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Link, withRouter} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import 'react-toastify/dist/ReactToastify.css';

import {KidsSubCategoryReverseMap, MenSubCategoryReverseMap, WomenSubCategoryReverseMap} from '../../config';
import {addToCart, addToCompare, addToWishlist, filterProducts} from '../../../actions'
import {getVisibleproducts} from '../../../services';
import ProductItem from "../../layouts/common/product-item";
import {fetchLocation} from "../../../services/util";


class ProductListing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            limit: 10,
            stateFilters: [],
            hasMoreItems: true,
            category: '',
            subCategory: ''
        };

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.showOnlySales) {
            return;
        }
        let {category, subCategory} = nextProps;
        if (category !== this.state.category || subCategory !== this.state.subCategory) {
            if (category) {
                category = category.toUpperCase();
            }
            if (subCategory) {
                subCategory = subCategory.toLowerCase();
            }
            this.props.filterProducts(category,
                category && subCategory ?
                    category === 'MEN' ?
                        MenSubCategoryReverseMap[subCategory] :
                        category === 'WOMEN' ?
                            WomenSubCategoryReverseMap[subCategory] :
                            KidsSubCategoryReverseMap[subCategory] :
                    '');
            this.setState({
                category: nextProps.category,
                subCategory: nextProps.subCategory,
            });
        }
    }


    fetchMoreItems = () => {
        const {showOnlySales, allProducts} = this.props;
        const finalProducts = [];
        if (showOnlySales) {
            allProducts.forEach(p => {
                if (p.isSale) {
                    finalProducts.push(p);
                }
            });
        }
        const products = showOnlySales ? finalProducts : this.props.filteredProducts;
        if (this.state.limit >= products.length) {
            this.setState({hasMoreItems: false});
            return;
        }
        // a fake async api call
        setTimeout(() => {
            const {limit} = this.state;
            this.setState({
                limit: limit + 10
            });
        }, 1500);
    };

    render() {
        const {addToCart, symbol, filteredProducts, cart, currentLocation} = this.props;
        if (!currentLocation) {
            fetchLocation();
        }
        let products = filteredProducts;
        return (
            <div>
                <div className="product-wrapper-grid">
                    <div className="container-fluid">
                        {
                            products.length > 0 ?
                                <InfiniteScroll
                                    dataLength={this.state.limit}
                                    next={this.fetchMoreItems}
                                    hasMore={this.state.hasMoreItems}
                                    loader={<div className="loading-cls"/>}
                                    endMessage={
                                        <div/>
                                    }
                                >
                                    <div className="row">
                                        {products.slice(0, this.state.limit).map((product, index) =>
                                            <div
                                                className={`${this.props.colSize === 3 ? 'col-xl-3 col-md-6 col-grid-box' : 'col-lg-' + this.props.colSize}`}
                                                key={index}>
                                                <ProductItem product={product} symbol={symbol} cart={cart}
                                                             location={currentLocation}
                                                             onAddToCartClicked={addToCart} key={index}/>
                                            </div>)
                                        }
                                    </div>
                                </InfiniteScroll>
                                :
                                <div className="row">
                                    <div className="col-sm-12 text-center section-b-space mt-6 no-found">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`}
                                             className="img-fluid mb-4"/>
                                        <h3>Sorry! Couldn't find the product you were looking For!!! </h3>
                                        <p>Please check if you have misspelt something.</p>
                                        <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">continue
                                            shopping</Link>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filteredProducts: getVisibleproducts(state.data, state.filters, true),
    allProducts: state.data.allProducts,
    cart: state.cartList.cart,
    filters: state.filters,
    symbol: state.data.symbol,
    currentLocation: state.data.location,
});

export default connect(
    mapStateToProps, {addToCart, addToWishlist, addToCompare, filterProducts}
)(withRouter(ProductListing))
