import request from '../utils/RequestManager';

import {config} from '../config';

const backendHost = config.BACKEND_HOST;

export function getProductsByCategoryAndSubCategory(category, subCategory) {
    return request({
        method: 'GET',
        baseURL: backendHost,
        url: `/products?category=${category}&subCategory=${subCategory}`,
    }).then(response => response.data);
}

export function getAllProducts() {
    return request({
        method: 'GET',
        baseURL: backendHost,
        url: `/products`,
    }).then(response => response.data);
}


export function getAllCountries() {
    return request({
        method: 'GET',
        baseURL: backendHost,
        url: `/countries/new`,
    }).then(response => response.data);
}

export function payRequest(products) {
    return request({
        method: 'POST',
        baseURL: backendHost,
        url: `/pay`,
        data: products,
    });
}

export function handlePaymentResponse(payment) {
    return request({
        method: 'POST',
        baseURL: backendHost,
        url: `/pay/response`,
        data: payment,
    }).then(response => response.data);
}

export function calculateShippingWeight(products, location, country, method) {
    return request({
        method: 'POST',
        baseURL: backendHost,
        url: `/shipping?location=${location}&country=${country}&method=${method}`,
        data: products,
    });
}

export function getAllShippingMethods(country) {
    return request({
        method: 'GET',
        baseURL: backendHost,
        url: `/shipping/methods?country=${country}`,
    }).then(response => response.data);
}