export const config = {
  BACKEND_HOST: "https://backend.lightaffect.am",
  // BACKEND_HOST: "http://localhost:8088",
  price20: process.env.REACT_APP_PRICE20,
  price100_250: process.env.REACT_APP_PRICE100_250,
  price250_500: process.env.REACT_APP_PRICE250_500,
  price500_1000: process.env.REACT_APP_PRICE500_1000,
  price1000_1500: process.env.REACT_APP_PRICE1000_1500,
  price1500_2000: process.env.REACT_APP_PRICE1500_2000,
  price20_AMD: process.env.REACT_APP_PRICE20_AMD,
  price100_250_AMD: process.env.REACT_APP_PRICE100_250_AMD,
  price250_500_AMD: process.env.REACT_APP_PRICE250_500_AMD,
  price500_1000_AMD: process.env.REACT_APP_PRICE500_1000_AMD,
  price1000_1500_AMD: process.env.REACT_APP_PRICE1000_1500_AMD,
  price1500_2000_AMD: process.env.REACT_APP_PRICE1500_2000_AMD,
  priceToArmeniaAMD: process.env.REACT_APP_PRICE_TO_ARMENIA_AMD,
  priceToArmeniaUSD: process.env.REACT_APP_PRICE_TO_ARMENIA_USD,
  deliveryDateInDays: process.env.REACT_APP_deliveryDateInDays,
  deliveryDateInDaysToArmenia: process.env.REACT_APP_deliveryDateInDaysToArmenia

};

export const MenSubCategoryReverseMap = {
  't-shirts': 'MEN_T_SHIRTS',
  'pants': 'MEN_PANTS',
  'jackets': 'MEN_JACKETS',
  'shirts': 'MEN_SHIRTS',
  'hoodies': 'MEN_HOODIES',
  'sweatshirts': 'MEN_SWEATSHIRTS',
  'coats': 'MEN_COATS',
  'shorts': 'MEN_SHORTS',
  'tracksuits': 'MEN_TRACKSUITS',
  'all': '',
};
export const WomenSubCategoryReverseMap = {
  't-shirts': 'WOMEN_T_SHIRTS',
  'skirts-and-pants': 'WOMEN_SHIRTS_AND_PANTS',
  'hoodies': 'WOMEN_HOODIES',
  'jackets': 'WOMEN_JACKETS',
  'dresses': 'WOMEN_DRESS',
  'shirts': 'WOMEN_SHIRTS',
  'sweatshirts': 'WOMEN_SWEATSHIRTS',
  'tracksuits': 'WOMEN_TRACKSUITS',
  'vests': 'WOMEN_VESTS',
  'all': '',
};

export const KidsSubCategoryReverseMap = {
  'dress': 'KIDS_DRESS',
  'hoodie': 'KIDS_HOODIE',
  'body-suits': 'KIDS_BODY_SUITS',
  'sweatshirts': 'KIDS_SWEATSHIRTS',
  'trousers': 'KIDS_TROUSERS',
  'hats': 'KIDS_HATS',
  'jackets': 'KIDS_JACKETS',
  'all': '',
};
