import React, {Component} from 'react';

class Breadcrumb extends Component {
    render (){
        const {title} = this.props;
        return (
            <div className="breadcrumb-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="page-title">
                                <h2>{title}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Breadcrumb;
