import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import '../../common/index.scss';
import Slider from 'react-slick';
import {Link} from 'react-router-dom';
// Import custom components
import Instagram from "../common/instagram";
import {svgFreeShipping, svgoffer, svgservice} from "../../../services/script"
import store from "../../../store";
import {fetchAllProducts} from "../../../actions";
import {getAllProducts as getAll} from "../../services/api";
import TopCollection from "./top-collection";

class Fashion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    document.getElementById("color").setAttribute("href", `#`);

    getAll().then(data => {
      store.dispatch(fetchAllProducts(data));
      this.setState({
        loading: false
      })
    });

    setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  }

  render() {
    const {loading} = this.state;
    const isMobile = window.innerWidth <= 768;
    if (loading) {
      return (<div/>);
    }

    return (
      <div>
        <Helmet>
          <title>Light Affect</title>
          <meta name="description" content="Light Affect Armenian Brand" />
        </Helmet>
        {/*Home Slider*/}
        <section className="p-0">
          <Slider className="slide-1 home-slider">
            <div>
              <div className="home home2 text-center"
                   style={{backgroundImage: isMobile ? "url('https://fs.lightaffect.am/download?filePath=/assets/webacht/1mobile-1712348843145.jpg')" : "url('https://fs.lightaffect.am/download?filePath=/assets/webacht/1 (2)-1712348817732.jpg')"}}>
                <div className="container">
                  <div className="row">
                    <div className="col" >
                      <div>
                        <div style={{height: '100%', marginTop: '45%'}}>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="home home1 text-center"
                   style={{backgroundImage: isMobile ? "url('https://fs.lightaffect.am/download?filePath=/assets/webacht/2mobile-1712348963311.jpg')" : "url('https://fs.lightaffect.am/download?filePath=/assets/webacht/2 (2)-1712348981734.jpg')"}}>
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <div >
                        <div style={{height: '100%'}}>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="home home1 text-center"
                   style={{backgroundImage: "url('https://fs.lightaffect.am/download?filePath=/assets/webacht/3 (1)-1712348899653.jpg')"}}>
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <div className="slider-contain">
                        <div style={{height: '100%'}}>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </section>
        {/*Home Section End*/}

        {/*collection banner*/}
        {/*Paragraph*/}
        <div className="title1  section-t-space">
          <h2 className="title-inner1">Section</h2>
        </div>
        {/*Paragraph End*/}
        <section className="pb-0" style={{marginBottom: '5%'}}>
          <div className="container">
            <div className="row" style={{alignItems: 'center', justifyContent: 'center'}}>
              <div className="col-md-5">
                <a href={`${process.env.PUBLIC_URL}/men/all`}>
                  <div className="collection-banner p-right text-center">
                    <img
                      src={`https://fs.lightaffect.am/download?filePath=/assets/webacht/a2 (1)-1712348741941.jpg`}
                      className="img-fluid" alt=""/>
                  </div>
                </a>
              </div>
              <div className="col-md-5" style={isMobile ? {marginTop: '10%'}: {}}>
                <Link to={`${process.env.PUBLIC_URL}/women/all`}>
                  <div className="collection-banner p-right text-center">
                    <img
                      src={`https://fs.lightaffect.am/download?filePath=/assets/webacht/1women1-1712335626150.jpg`}
                      className="img-fluid" alt=""/>
                  </div>
                </Link>
              </div>
              {/*<div className="col-md-4">
                <a href={`${process.env.PUBLIC_URL}/kids/all`}>
                  <div className="collection-banner p-right text-center">
                    <img
                        src={`https://fs.lightaffect.am/download?filePath=/assets/webacht/33-1608403702851.jpg`}
                      className="img-fluid" alt=""/>
                  </div>
                </a>
              </div>*/}
              {/*<div className="col-md-3">
                <a href={`${process.env.PUBLIC_URL}/accessories/all`}>
                  <div className="collection-banner p-right text-center">
                    <img
                      src={`https://scontent.fevn1-3.fna.fbcdn.net/v/t1.0-9/s960x960/36506485_2178540725713519_5931678439428849664_o.jpg?_nc_cat=111&_nc_ohc=ifVWNzRw36YAQk22T6-iHxnR2b5MtoL1PgxD8fdA3i-cLsaaHx1OLOg3g&_nc_ht=scontent.fevn1-3.fna&oh=1bf857bb869904c731a868904cfccd92&oe=5E827513`}
                      className="img-fluid" alt=""/>
                    <div className="contain-banner">
                      <div>
                        <h3 style={{color: 'white'}}>Accessories</h3>
                      </div>
                    </div>
                  </div>
                </a>
              </div>*/}
            </div>
          </div>
        </section>
        {/*collection banner end*/}


        {/*Parallax banner*/}
        <section className="p-0">
          <div className="full-banner parallax-banner1 parallax text-center p-left"
               style={{
                 backgroundImage: "url('https://fs.lightaffect.am/download?filePath=/assets/webacht/qo hagova-1626704227809.jpg')",
                 marginTop: '50px'
               }}>
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="banner-contain" style={{background: 'transparent'}}>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Parallax banner End*/}

        {/*<TopCollection type={'women'}/>*/}

        {/*service layout*/}
        <div>
          {/*Paragraph*/}
          <div className="title1  section-t-space">
            <h2 className="title-inner1">Services</h2>
          </div>
          {/*Paragraph End*/}
          <div className="container" style={{cursor: 'pointer'}}>
            <section className="service border-section small-section ">
              <div className="row">
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{__html: svgFreeShipping}}/>
                    <div className="media-body">
                      <h4>world wide shipping</h4>
                      <p>world wide shipping</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{__html: svgservice}}/>
                    <div className="media-body">
                      <h4>24 X 7 service</h4>
                      <p>online service for new customer</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 service-block">
                  <div className="media">
                    <div dangerouslySetInnerHTML={{__html: svgoffer}}/>
                    <div className="media-body">
                      <h4>festival offer</h4>
                      <p>new online special festival offer</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/*service layout End*/}

        <Instagram/>

      </div>
    )


  }
}

export default Fashion;
