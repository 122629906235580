import React, {Component} from 'react';

class AcceptCookies extends Component {

    render() {
        let acceptCookies = this.getAcceptCookies();
        if (acceptCookies) {
            return (<div/>);
        }

        return (
            <div id="cookie-banner"
                 style={{
                     cursor: 'pointer',
                     opacity: '0.8',
                     zIndex: '100',
                     position: 'fixed',
                     left: 0,
                     right: 0,
                     bottom: 0,
                     color: '#fff',
                     textAlign: 'center',
                     padding: '1rem 1rem',
                     background: '#000',
                     transition: 'all 5s cubic-bezier(0.19, 1, 0.22, 1)',
                     '-webkit-transition': 'all 5s cubic-bezier(0.19, 1, 0.22, 1)',
                     '-moz-transition': 'all 5s cubic-bezier(0.19, 1, 0.22, 1)',
                     '-ms-transition': 'all 5s cubic-bezier(0.19, 1, 0.22, 1)',
                     '-o-transition': 'all 5s cubic-bezier(0.19, 1, 0.22, 1)',
                 }}>
                <span>We use our own and third-party cookies to improve your experience and our services,
                    and to analyse the use of our website. If you continue browsing, we take that
                    to mean that you accept their use.</span>
                <span id="cookie-banner-button" style={{
                    display: 'inline-block',
                    cursor: 'pointer',
                    padding: '0.85rem 1rem',
                    marginLeft: '0.45rem',
                    color: '#000',
                    fontSize: '0.75rem',
                    textDecoration: 'none',
                    letterSpacing: '1px',
                    background: '#fff',
                }}
                      onClick={this.setAcceptCookies.bind(this)}>Ok</span>
            </div>
        )
    }

    getAcceptCookies() {
        return localStorage.getItem('accept');
    }

    setAcceptCookies() {
        localStorage.setItem('accept', "true");
        this.setState({
            smth: ''
        })
    }
}

export default AcceptCookies;
