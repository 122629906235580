import React from 'react';
import {connect} from 'react-redux';

import CartPage from '../components/common/headers/common/cart-header'
import {removeFromCart} from '../actions'
import {getCartTotal} from '../services'

const CartContainer = ({cartList, total, symbol, removeFromCart, location}) => (
  <li className="onhover-div mobile-cart">
    <div className="cart-qty-cls">{cartList.length}</div>
    <a href={`${process.env.PUBLIC_URL}/cart`} ><img src={`${process.env.PUBLIC_URL}/assets/images/icon/cart32.png`}
                                                     color='white' style={{height:'29px', width:'35px'}}
                                                     className="img-fluid" alt=""/>
      <i className="fa fa-shopping-cart"/></a>
    <ul className="show-div shopping-cart">
      {cartList.map((item, index) => (
        <CartPage key={index} item={item} total={total} symbol={symbol}
                  removeFromCart={() => removeFromCart(item, item.size)}
                  location={location}/>
      ))}
      {(cartList.length > 0) ?
        <div>
          <li>
            <div className="total" style={{borderTop: 0}}>
              <h5>subtotal : <span>{symbol}{total}</span></h5>
            </div>
          </li>
          <li>
            <div className="buttons">
              <a href={`${process.env.PUBLIC_URL}/cart`} className="view-cart">view cart</a>
            </div>
          </li>
        </div>
        :
        <li><h5>Your cart is currently empty.</h5></li>}
    </ul>

  </li>
);


function mapStateToProps(state) {
  return {
      cartList: state.cartList.cart,
      total: getCartTotal(state.cartList.cart, state.data.location),
      symbol: state.data.symbol,
      location: state.data.location,
  }
}

export default connect(mapStateToProps, {removeFromCart})(CartContainer);
