import React, { Component } from 'react';
import Slider from 'react-slick';

import {Slider5, Slider7} from "../../../services/script"

class Instagram extends Component {

    constructor (props) {
        super (props)

        this.state = {
            InstaData: []
        }
    }

    componentWillMount() {
        // const instagram = ig({
        //     accessToken: '8295761913.aa0cb6f.2914e9f04dd343b8a57d9dc9baca91cc',
        // });

        // const users = instagram.media();
        // users.then(res => this.setState({InstaData:res.data}));
        this.setState({
            InstaData: [
                {
                    link: 'https://www.instagram.com/light_affect/',
                    photoUrl: 'https://fs.lightaffect.am/download?filePath=/assets/webacht/ararat_sweatshirt-1700479708268.jpg'
                },
                {
                    link: 'https://www.instagram.com/light_affect/',
                    photoUrl: 'https://fs.lightaffect.am/download?filePath=/assets/webacht/charents1_resized-1700479717036.png'
                },
                {
                    link: 'https://www.instagram.com/light_affect/',
                    photoUrl: 'https://fs.lightaffect.am/download?filePath=/assets/webacht/fire_resized-1700479726163.jpg'
                },
                {
                    link: 'https://www.instagram.com/light_affect/',
                    photoUrl: 'https://fs.lightaffect.am/download?filePath=/assets/webacht/gampr3_resized-1700479737704.png'
                },
                {
                    link: 'https://www.instagram.com/light_affect/',
                    photoUrl: 'https://fs.lightaffect.am/download?filePath=/assets/webacht/jacket grey hay_resized-1700479745157.jpg'
                },
                {
                    link: 'https://www.instagram.com/light_affect/',
                    photoUrl: 'https://fs.lightaffect.am/download?filePath=/assets/webacht/jacket insta_resized-1700479753753.png'
                },
                {
                    link: 'https://www.instagram.com/light_affect/',
                    photoUrl: 'https://fs.lightaffect.am/download?filePath=/assets/webacht/kirq insta_resized-1700479761891.png'
                },
                {
                    link: 'https://www.instagram.com/light_affect/',
                    photoUrl: 'https://fs.lightaffect.am/download?filePath=/assets/webacht/ktas_resized-1700479772480.png'
                },
            ]
        })


    }

    render (){

        const {InstaData} = this.state;
        const {type} = this.props;

        var Sliders = {};
        if(type === 'watch'){
            Sliders = Slider5;
        }else{
            Sliders = Slider7;
        }


        return (
            <section className={`instagram`}>
                <div className={`container${(type === 'watch')?'':'-fluid'}`}>
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <h2 className="title-borderless"># instagram
                                </h2>
                                <Slider {...Sliders} className="slide-7 no-arrow slick-instagram">
                                    {InstaData.map((pic,i) =>
                                        <div key={i}>
                                            <a href={pic.link} target="_blank">
                                                <div className="instagram-box" style={{ height: 'auto', backgroundColor: 'transparent', width: '100%'}}>
                                                    <img src={`${pic.photoUrl}`} style={{objectFit: 'cover'}} height="100%" width="auto" alt="Avatar" className="w-100" />
                                                    <div className="overlay">
                                                        <i className="fa fa-instagram" aria-hidden="true"/>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                </Slider>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Instagram;
