import {ADD_TO_CART, DECREMENT_QTY, REMOVE_FROM_CART, CLEAR_CART} from "../constants/ActionTypes";
import {toast} from "react-toastify";


export default function cartReducer(state = {
  cart: []
}, action) {
  switch (action.type) {
    case ADD_TO_CART:
      const productId = action.product.id;
      const size = action.size;
      const qty = action.qty;

      if (state.cart.findIndex(product => product.id === productId && product.size === size) !== -1) {
        const cart = state.cart.reduce((cartAcc, product) => {
          if (product.id === productId && product.size === size) {
            let canAdd = true;
            if (qty > 0) {
              product.sizeAndCountList.forEach(item => {
                if (item.size === size && (product.qty + qty) > item.count) {
                  canAdd = false;
                }
              });
            }
            if (canAdd) {
              toast.success(`${product.name} | ${product.size} Added to Cart`);
              cartAcc.push({
                ...product,
                qty: product.qty + qty,
                size: size,
                sum: (product.isSale ? product.salePrice : product.price) * (product.qty + qty)
              })
            } else {
              cartAcc.push({
                ...product,
              })
            }
          } else {
            cartAcc.push({
              ...product,
            })
          }
          return cartAcc
        }, []);

        return {...state, cart}
      }

      toast.success(`${action.product.name} | ${action.size} Added to Cart`);

      return {
        ...state,
        cart: [...state.cart, {
          ...action.product,
          qty: action.qty,
          size: action.size,
          sum: (action.product.isSale ? action.product.salePrice : action.product.price) * action.qty
        }]
      };

    case DECREMENT_QTY:
      if (state.cart.findIndex(product => product.id === action.productId) !== -1) {
        const cart = state.cart.reduce((cartAcc, product) => {
          if (product.id === action.productId && product.qty > 1) {
            cartAcc.push({
              ...product,
              qty: product.qty - 1,
              sum: (product.price * product.discount / 100) * (product.qty - 1)
            })
          } else {
            cartAcc.push(product)
          }

          return cartAcc
        }, []);

        return {...state, cart}
      }

      return {
        ...state,
        cart: [...state.cart, {...action.product, qty: action.qty, sum: action.product.price * action.qty}]
      };

    case REMOVE_FROM_CART:
      return {
        cart: state.cart.filter(item => !(item.id === action.product_id.id && item.size === action.size))
      };
    case CLEAR_CART:
      return {
        cart: []
      };

    default:
  }
  return state;
}
