import React, {Component} from 'react';
import {connect} from 'react-redux'
import SimpleReactValidator from 'simple-react-validator';

import Breadcrumb from "../common/breadcrumb";
import {removeFromWishlist} from '../../actions'
import {getCartTotal, getCartTotalWeight} from "../../services";
import {calculateShippingWeight, getAllCountries, getAllShippingMethods, payRequest} from "../services/api";
import {toast} from "react-toastify";
import {fetchLocation} from "../../services/util";

class checkOut extends Component {

    constructor(props) {
        super(props);

        this.state = {
            payment: 'card',
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            country: '',
            address: '',
            city: '',
            state: '',
            postalCode: '',
            create_account: '',
            countries: [],
            shippingPrice: 0,
            shippingMethods: [],
            selectedMethod: null
        };
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        const {location} = this.props;
        if (!location) {
            fetchLocation();
        }
        getAllCountries()
            .then(data => {
                this.setState({
                    countries: data,
                    country: data[0].name
                });
                this.updateShippingPriceFromWeightBackend(this.state.country);

            });
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);

    };

    updateShippingPriceFromWeightBackend(country) {
        let {location} = this.props;
        if (!location) {
            location = "OTHER";
        }

        let city = '';
        let state = '';
        if (country === "United States - LOS ANGELES") {
            state = 'California';
            city = 'Los Angeles';
        } else if (country === "Russia - MOSCOW") {
            state = 'Moscow';
            city = 'Moscow';
        } else if (country === "Armenia - YEREVAN") {
            state = 'Yerevan';
            city = 'Yerevan';
        }

        const {cartItems} = this.props;

        const products = [];
        cartItems.forEach(item => {
            products.push({
                productId: item.id,
                qty: item.qty,
                size: item.size,
            })
        });
        getAllShippingMethods(country)
            .then(methods => {
                this.setState({
                    shippingMethods: methods,
                    selectedMethod: methods[0]
                });
                this.calculateShippingPrice(products, location, country, this.state.selectedMethod.shippingMethod);
            });

        this.setState({
            city,
            state
        });
    }

    calculateShippingPrice = (products, location, country, shippingMethod) => {
        calculateShippingWeight(products, location, country, shippingMethod)
            .then(response => {
                this.setState({
                    shippingPrice: response.data
                })
            })
            .catch(err => {
                toast.error("Shipping calculation failed. Please contact us.");
            });
    };

    changeMethod = (event) => {
        const method = event.target.value;
        let shippingMethod = null;
        this.state.shippingMethods.forEach(item => {
            if (item.shippingMethod === method) {
                shippingMethod = item;
            }
        });
        let {location} = this.props;
        if (!location) {
            location = "OTHER";
        }
        const {cartItems} = this.props;

        const products = [];
        cartItems.forEach(item => {
            products.push({
                productId: item.id,
                qty: item.qty,
                size: item.size,
            })
        });
        this.setState({
            selectedMethod: shippingMethod
        });

        this.calculateShippingPrice(products, location, this.state.country, shippingMethod.shippingMethod);
    };

    handlePayRequest = () => {

        if (this.validator.allValid()) {
            const {cartItems, location} = this.props;
            const {firstName, lastName, phone, email, country, address, city, state, postalCode} = this.state;
            const finalLocation = location ? location : "OTHER";

            const products = [];
            cartItems.forEach(item => {
                products.push({
                    productId: item.id,
                    qty: item.qty,
                    size: item.size,
                })
            });
            const result = {
                shippingMethod: this.state.selectedMethod.shippingMethod,
                country: this.state.country,
                products,
                finalLocation,
                clientInfo: {
                    firstName,
                    lastName,
                    phone,
                    email,
                    country,
                    address,
                    city,
                    state,
                    postalCode
                },
                location
            };
            payRequest(result)
                .then(response => {
                    window.location.href = response.data;
                })
                .catch(err => {
                    if (err.response.status === 409) {
                        toast.error(err.response.data)
                    } else {
                        toast.error("Payment Failed. Please check your cart or contact us.");
                    }
                });
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.forceUpdate();
        }
    };

    render() {
        const {cartItems, symbol, total, location} = this.props;
        const {countries, shippingPrice} = this.state;
        const fromArmenia = location === "ARMENIA";
        const stateCityDisabled = this.state.country === "United States - LOS ANGELES" || this.state.country === "Russia - MOSCOW" || this.state.country === "Armenia - YEREVAN";

        return (
            <div>

                <Breadcrumb title={'Checkout'}/>

                <section className="section-b-space">
                    <div className="container padding-cls">
                        <div className="checkout-page">
                            <div className="checkout-form">
                                <form>
                                    <div className="checkout row">
                                        <div className="col-lg-6 col-sm-12 col-xs-12">
                                            <div className="checkout-title">
                                                <h3>Shipping Address</h3>
                                            </div>
                                            <div className="row check-out">
                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="field-label">First Name</div>
                                                    <input type="text" name="firstName" value={this.state.firstName}
                                                           onChange={this.setStateFromInput}/>
                                                    {this.validator.message('firstName', this.state.firstName, 'required')}
                                                </div>
                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="field-label">Last Name</div>
                                                    <input type="text" name="lastName" value={this.state.lastName}
                                                           onChange={this.setStateFromInput}/>
                                                    {this.validator.message('lastName', this.state.lastName, 'required')}
                                                </div>
                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="field-label">Phone</div>
                                                    <input type="text" name="phone" value={this.state.phone}
                                                           onChange={this.setStateFromInput}/>
                                                    {this.validator.message('phone', this.state.phone, 'required')}
                                                </div>
                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="field-label">Email Address</div>
                                                    <input type="text" name="email" value={this.state.email}
                                                           onChange={this.setStateFromInput}/>
                                                    {this.validator.message('email', this.state.email, 'required|email')}
                                                </div>
                                                <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                    <div className="field-label">Country</div>
                                                    <select name="country" value={this.state.country}
                                                            onChange={(event) => {
                                                                this.updateShippingPriceFromWeightBackend(event.target.value);
                                                                this.setStateFromInput(event);
                                                            }}>
                                                        {
                                                            countries.map((item, index) => {
                                                                return (<option key={index}>{item.name}</option>)
                                                            })
                                                        }
                                                    </select>
                                                    {this.validator.message('country', this.state.country, 'required')}
                                                </div>
                                                <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                    <div className="field-label">State / Country</div>
                                                    <input type="text" name="state" id="state" value={this.state.state} style={ stateCityDisabled ? {backgroundColor: 'gainsboro'}: {}}
                                                           disabled={stateCityDisabled ? 'disabled' : ""}
                                                           onChange={this.setStateFromInput}/>
                                                    {this.validator.message('state', this.state.state, 'required')}
                                                </div>
                                                <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                    <div className="field-label">Town / City</div>
                                                    <input type="text" name="city" id="city" value={this.state.city} style={ stateCityDisabled ? {backgroundColor: 'gainsboro'}: {}}
                                                           disabled={stateCityDisabled ? 'disabled' : ""}
                                                           onChange={this.setStateFromInput}/>
                                                    {this.validator.message('city', this.state.city, 'required')}
                                                </div>
                                                <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                    <div className="field-label">Address</div>
                                                    <input type="text" name="address" value={this.state.address}
                                                           onChange={this.setStateFromInput}
                                                           placeholder="Street address"/>
                                                    {this.validator.message('address', this.state.address, 'required|min:5|max:120')}
                                                </div>
                                                <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                    <div className="field-label">Postal Code</div>
                                                    <input type="text" name="postalCode" value={this.state.postalCode}
                                                           onChange={this.setStateFromInput}/>
                                                    {this.validator.message('postalCode', this.state.postalCode, 'required')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12 col-xs-12">
                                            <div className="checkout-details">
                                                <div className="order-box">
                                                    <div className="title-box">
                                                        <div>Product <span> Total</span></div>
                                                    </div>
                                                    <ul className="sub-total">
                                                        {cartItems.map((item, index) => {
                                                            return <li key={index}><b>{item.name}</b> ( {item.size} )
                                                                × {item.qty}
                                                                <span>{symbol} {item.qty * (item.isSale ? item.saleMap[location] : item.priceMap[location])}</span>
                                                            </li>
                                                        })
                                                        }
                                                    </ul>
                                                    <ul className="sub-total">
                                                        <li>Shipping Method</li>
                                                        <div onChange={this.changeMethod}>
                                                            {
                                                                this.state.shippingMethods &&
                                                                this.state.shippingMethods.map((method, index) => {
                                                                    return (
                                                                        <li>
                                                                            <div className="radio-option">
                                                                                <input type="radio"
                                                                                       name="shipping-group"
                                                                                       id="shipping-2"
                                                                                       value={method.shippingMethod}
                                                                                       checked={this.state.selectedMethod.shippingMethod === method.shippingMethod}/>
                                                                                <label htmlFor="payment-2"
                                                                                       style={{marginLeft: '2%'}}>
                                                                                    {method.name} | {method.shippingPeriod} &nbsp;

                                                                                </label>
                                                                                <a
                                                                                    href={this.getShippingUrl(method.shippingMethod)}
                                                                                    target="_blank"
                                                                                    style={{textDecoration: 'none'}}><img
                                                                                    src="https://fs.lightaffect.am/download?filePath=/assets/webacht/iconfinder_ic_info_48px_3669162 (1)-1613681370731.png"
                                                                                    alt="ℹ"/> </a>
                                                                            </div>
                                                                        </li>
                                                                    );
                                                                })}
                                                        </div>
                                                    </ul>
                                                    <ul className="sub-total">
                                                        <li>Subtotal <span className="count">{symbol}{total}</span></li>
                                                        <li>Shipping <span
                                                            className="count">{symbol}{shippingPrice}</span></li>
                                                    </ul>

                                                    <ul className="total">
                                                        <li>Total <span
                                                            className="count">{symbol}{total + shippingPrice}</span>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="payment-box">
                                                    <div className="upper-box">
                                                        <div className="payment-options">
                                                            <ul>
                                                                <li>
                                                                    <div className="radio-option">
                                                                        <input type="radio" name="payment-group"
                                                                               id="payment-2" value={true}
                                                                               defaultChecked={true}/>
                                                                        <label htmlFor="payment-2">
                                                                            {fromArmenia ? 'Credit Card / PayPal' : 'Credit Card'}
                                                                            <span className="image"><img
                                                                                src={fromArmenia
                                                                                    ? `${process.env.PUBLIC_URL}/assets/images/paypal.png`
                                                                                    : 'https://fs.lightaffect.am/download?filePath=/assets/webacht/paypal-1598038597789.png'}
                                                                                alt=""/></span></label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    {(total !== 0) ?
                                                        <div className="text-right">
                                                            <button type="button" className="btn-solid btn"
                                                                    onClick={() => this.handlePayRequest()}>
                                                                Place Order
                                                            </button>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                {/*<ToastContainer/>*/}
            </div>
        )
    }

    getShippingUrl(shippingMethod) {
        if (shippingMethod === "ALADIN") {
            return "https://www.facebook.com/llc.aladin.express";
        } else if (shippingMethod === "GLOBBING") {
            return "https://globbing.com";
        } else if (shippingMethod === "GG") {
            return "https://www.facebook.com/ggTaxi/";
        }  else if (shippingMethod === "AEKS") {
            return "https://www.aekshipping.com/";
        } else {
            return "https://www.haypost.am/en";
        }
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    location: state.data.location,
    total: getCartTotal(state.cartList.cart, state.data.location),
    totalWeight: getCartTotalWeight(state.cartList.cart)
});

export default connect(
    mapStateToProps,
    {removeFromWishlist}
)(checkOut)
