// Get Unique Brands from Json Data
import cart from "../reducers/cart";
import store from "../store";
import {clearCart} from "../actions";

export const getBrands = (products) => {
    var uniqueBrands = [];
    products.map((product, index) => {
        if (product.tags) {
            product.tags.map((tag) => {
                if (uniqueBrands.indexOf(tag) === -1) {
                    uniqueBrands.push(tag);
                }
            })
        }
    })
    return uniqueBrands;
}

// Get Unique Colors from Json Data
export const getColors = (products) => {
    var uniqueColors = [];
    products.map((product, index) => {
        if (product.colors) {
            product.colors.map((color) => {
                if (uniqueColors.indexOf(color) === -1) {
                    uniqueColors.push(color);
                }
            })
        }
    })
    return uniqueColors;
}

// Get Minimum and Maximum Prices from Json Data
export const getMinMaxPrice = (products) => {
    let min = 100, max = 1000;

    products.map((product, index) => {
        let v = product.price;
        min = (v < min) ? v : min;
        max = (v > max) ? v : max;
    })

    return {'min': min, 'max': max};
}

export const getVisibleproducts = (data, {brand, color, value, sortBy}, isSale = false) => {
    return data.products && data.products !== {} ? data.products.sort((product1, product2) => {
        if (sortBy === 'HighToLow') {
            const product2Price = product2.isSale ? product2.saleMap['ARMENIA'] : product2.priceMap['ARMENIA'];
            const product1Price = product1.isSale ? product1.saleMap['ARMENIA'] : product1.priceMap['ARMENIA'];
            return product2Price < product1Price ? -1 : 1;
        } else if (sortBy === 'LowToHigh') {
            const product2Price = product2.isSale ? product2.saleMap['ARMENIA'] : product2.priceMap['ARMENIA'];
            const product1Price = product1.isSale ? product1.saleMap['ARMENIA'] : product1.priceMap['ARMENIA'];
            return product2Price > product1Price ? -1 : 1;
        } else if (sortBy === 'Newest') {
            return product2.id < product1.id ? -1 : 1;
        } else if (sortBy === 'AscOrder') {
            return product1.name.localeCompare(product2.name);
        } else if (sortBy === 'DescOrder') {
            return product2.name.localeCompare(product1.name);
        } else if (isSale) {
            return product2.category > product1.category ? -1 : 1;
        } else {
            return product2.id < product1.id ? -1 : 1;
        }
    }) : data.products;
}

export const getCartTotal = (cartItems, location) => {
    var total = 0;
    for (var i = 0; i < cartItems.length; i++) {
        if (!cartItems[i].priceMap) {
            store.dispatch(clearCart());
        }
        total += parseInt(cartItems[i].qty, 10) * parseInt(cartItems[i].isSale ? cartItems[i].saleMap[location] : cartItems[i].priceMap[location], 10);
    }
    return total;
};

export const getCartTotalWeight = cartItems => {
    var total = 0;
    for (var i = 0; i < cartItems.length; i++) {
        total += parseInt(cartItems[i].qty, 10) * parseInt(cartItems[i].weightInGrams, 10);
    }
    return total;
};

// Get Trending Tag wise Collection
export const getTrendingTagCollection = (products, type, tag) => {
    const items = products.filter(product => {
        return product.category === type && product.tags.includes(tag);
    })
    return items.slice(0, 8)
}

// Get Trending Collection
export const getTrendingCollection = (products, type) => {
    const items = products.filter(product => {
        return product.category === type;
    })
    return items.slice(0, 8)
}

// Get Special 5 Collection
export const getSpecialCollection = (products, type) => {
    const items = products.filter(product => {
        return product.category === type;
    })
    return items.slice(0, 5)
}

// Get TOP Collection
export const getTopCollection = products => {
    const items = products.filter(product => {
        return product.rating > 4;
    })
    return items.slice(0, 8)
}

// Get New Products
export const getNewProducts = (products, type) => {
    const items = products.filter(product => {
        return product.new === true && product.category === type;
    })

    return items.slice(0, 8)
}

// Get Related Items
export const getRelatedItems = (products, type) => {
    const items = products.filter(product => {
        return product.category === type;
    })

    return items.slice(0, 4)

}

// Get Best Seller Furniture
export const getBestSellerProducts = (products, type) => {
    const items = products.filter(product => {
        return product.sale === true && product.category === type;
    })

    return items.slice(0, 8)
}

// Get Best Seller
export const getBestSeller = (products, id) => {
    const items = products.filter(product => {
        return product.isSale === true && product.id !== id;
    })

    return shuffle(items).slice(0, 8)
}
// Get Similar products
export const getSimilarProducts = (products, category, subCategory, id) => {
    const items = products.filter(product => {
        return product.category === category && product.subCategory === subCategory && product.id !== id;
    });

    return shuffle(items).slice(0, 8)
};

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

// Get Mens Wear
export const getMensWear = products => {
    const items = products.filter(product => {
        return product.category === 'men';
    })

    return items.slice(0, 8)
}

// Get Womens Wear
export const getWomensWear = products => {
    const items = products.filter(product => {
        return product.category === 'women';
    })

    return items.slice(0, 8)
}

// Get Single Product
export const getSingleItem = (products, id) => {

    const items = products.find((element) => {
        return element.id === id;
    })
    return items;
}

// Get Feature Products
export const getFeatureImages = (products, type) => {

    const items = products.filter(product => {
        return product.type === type;
    })
    return items;
}


