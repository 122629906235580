
// Get Products
export const FETCH_PRODUCTS_BEGIN = 'FETCH_PRODUCTS_BEGIN';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const FETCH_SINGLE_PRODUCT = 'FETCH_SINGLE_PRODUCT';

// FILTERS
export const FILTER_BRAND = 'FILTER_BRAND';
export const FILTER_COLOR = 'FILTER_COLOR';
export const FILTER_PRICE = 'FILTER_PRICE';
export const SORT_BY = 'SORT_BY';

export const CHANGE_CURRENCY = 'CHANGE_CURRENCY';
export const CHANGE_BY_CATEGORY_AND_SUBCATEGORY = 'CHANGE_BY_CATEGORY_AND_SUBCATEGORY';


// Cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const INCREMENT_QTY = 'INCREMENT_QTY';
export const DECREMENT_QTY = 'DECREMENT_QTY';



// WishList
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';


// Compare
export const ADD_TO_COMPARE = 'ADD_TO_COMPARE';
export const REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE';



// CheckOut Process
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE';


// Location

export const CHANGE_LOCATION = 'CHANGE_LOCATION';

