import React, {Component} from 'react';
import Slider from 'react-slick';
import {connect} from 'react-redux'
import {Product4} from '../../../services/script'
import {addToCart, addToCompare, addToWishlist} from "../../../actions/index";
import ProductItem from '../common/product-item';

class TopCollection extends Component {

  render() {

    const {items, symbol, addToCart, addToWishlist} = this.props;
    items.sort(() => Math.random() - 0.5);
    if (!items || items.length < 3) {
      return (<div/>);
    }

    return (
      <div>
        {/*Paragraph*/}
        <div className="title1  section-t-space">
          <h4>special offer</h4>
          <h2 className="title-inner1">sale</h2>
        </div>
        {/*Paragraph End*/}
        <section className="section-b-space p-t-0">
          <div className="container">
            <div className="row">
              <div className="col">
                <Slider {...(Product4)} className="product-4 product-m no-arrow">
                  {items.slice(0,20).map((product, index) =>
                    <div key={index}>
                      <ProductItem product={product} symbol={symbol}
                                   onAddToWishlistClicked={() => addToWishlist(product)}
                                   onAddToCartClicked={addToCart} key={index}/>
                    </div>)
                  }
                </Slider>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  items: state.data.allProducts,
  symbol: state.data.symbol
});

export default connect(mapStateToProps, {addToCart, addToWishlist, addToCompare})(TopCollection);
