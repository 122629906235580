import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {ScrollContext} from 'react-router-scroll-4';
import {IntlProvider} from 'react-redux-multilingual'
import './index.scss';
// Import custom components
import store from './store';
import translations from './constants/translations'
// Layouts
import Fashion from './components/layouts/fashion/main';
//Collection Pages
import CollectionNoSidebar from "./components/collection/collection-no-sidebar";
// Product Pages
import NoSideBar from "./components/products/no-sidebar";
// Features
import Layout from './components/app'
import Cart from './components/cart'
import checkOut from './components/checkout'
import orderSuccess from './components/checkout/success-page'
// Extra Pages
import aboutUs from './components/pages/about-us'
import termsAndConditions from './components/pages/terms-and-conditions'
import privacyPolicy from './components/pages/privacy-policy'
import PageNotFound from './components/pages/404'
import lookbook from './components/pages/lookbook'
import Login from './components/pages/login'
import Register from './components/pages/register'
import Collection from './components/pages/collection'
import ForgetPassword from './components/pages/forget-password'
import Contact from './components/pages/contact'
import Dashboard from './components/pages/dashboard'
import Faq from './components/pages/faq'
// Blog Pages
// Theme Element
// Product Elements
// Portfolio Features


class Root extends React.Component {


  render() {

    return (
      <Provider store={store}>
        <IntlProvider translations={translations} locale='en'>
          <BrowserRouter basename={'/'}>
            <ScrollContext>
              <Switch>
                <Layout>
                  <Route exact path={`${process.env.PUBLIC_URL}/`} component={Fashion}/>

                  {/*Routes For Features (Product Collection) */}
                  {/*<Route path={`${process.env.PUBLIC_URL}/left-sidebar/collection`} component={CollectionLeftSidebar}/>*/}
                  <Route path={`${process.env.PUBLIC_URL}/product/:id`} component={NoSideBar}/>
                  <Route path={`${process.env.PUBLIC_URL}/sale`}
                         component={routeProps => <CollectionNoSidebar sale="sale" {...routeProps}/>}/>
                  <Route path={`${process.env.PUBLIC_URL}/men/:subCategory`}
                         component={routeProps => <CollectionNoSidebar category="men" {...routeProps}/>}/>
                  <Route path={`${process.env.PUBLIC_URL}/women/:subCategory`}
                         component={routeProps => <CollectionNoSidebar category="women" {...routeProps}/>}/>
                  <Route path={`${process.env.PUBLIC_URL}/kids/:subCategory`}
                         component={routeProps => <CollectionNoSidebar category="kids" {...routeProps}/>}/>
                  <Route path={`${process.env.PUBLIC_URL}/accessories/:subCategory`}
                         component={routeProps => <CollectionNoSidebar category="accessories" {...routeProps}/>}/>
                  {/*                  <Route path={`${process.env.PUBLIC_URL}/right-sidebar/collection`}
                         component={CollectionRightSidebar}/>
                  <Route path={`${process.env.PUBLIC_URL}/full-width/collection`} component={CollectionFullWidth}/>
                  <Route path={`${process.env.PUBLIC_URL}/a/metro/collection`} component={CollectionMetro}/>*/}

                  {/*Routes For Single Product*/}
                  {/*
                  <Route path={`${process.env.PUBLIC_URL}/no-sidebar/product/:id`} component={NoSideBar}/>
                  <Route path={`${process.env.PUBLIC_URL}/left-sidebar/product/:id`} component={LeftSideBar}/>
                  <Route path={`${process.env.PUBLIC_URL}/right-sidebar/product/:id`} component={RightSideBar}/>
                  <Route path={`${process.env.PUBLIC_URL}/col-left/product/:id`} component={ColumnLeft}/>
                  <Route path={`${process.env.PUBLIC_URL}/col-right/product/:id`} component={ColumnRight}/>
                  <Route path={`${process.env.PUBLIC_URL}/accordian/product/:id`} component={Accordian}/>
                  <Route path={`${process.env.PUBLIC_URL}/column/product/:id`} component={Column}/>
                  <Route path={`${process.env.PUBLIC_URL}/left-image/product/:id`} component={LeftImage}/>
                  <Route path={`${process.env.PUBLIC_URL}/right-image/product/:id`} component={RightImage}/>
                  <Route path={`${process.env.PUBLIC_URL}/vertical/product/:id`} component={Vertical}/>
*/}


                  {/*Routes For custom Features*/}
                  <Route path={`${process.env.PUBLIC_URL}/cart`} component={Cart}/>
                  <Route path={`${process.env.PUBLIC_URL}/checkout`} component={checkOut}/>
                  <Route path={`${process.env.PUBLIC_URL}/order-success`} component={orderSuccess}/>

                  <Route path={`${process.env.PUBLIC_URL}/sales/orders`} component={aboutUs}/>

                  {/*Routes For Extra Pages*/}
                  <Route path={`${process.env.PUBLIC_URL}/about-us`} component={aboutUs}/>
                  <Route path={`${process.env.PUBLIC_URL}/terms-conditions`} component={termsAndConditions}/>
                  <Route path={`${process.env.PUBLIC_URL}/privacy-policy`} component={privacyPolicy}/>
                  <Route path={`${process.env.PUBLIC_URL}/not-found`} component={PageNotFound}/>
                  <Route path={`${process.env.PUBLIC_URL}/pages/lookbook`} component={lookbook}/>
                  <Route path={`${process.env.PUBLIC_URL}/pages/login`} component={Login}/>
                  <Route path={`${process.env.PUBLIC_URL}/pages/register`} component={Register}/>
                  <Route path={`${process.env.PUBLIC_URL}/pages/collection`} component={Collection}/>
                  <Route path={`${process.env.PUBLIC_URL}/pages/forget-password`} component={ForgetPassword}/>
                  <Route path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                  <Route path={`${process.env.PUBLIC_URL}/pages/dashboard`} component={Dashboard}/>
                  <Route path={`${process.env.PUBLIC_URL}/pages/faq`} component={Faq}/>

                  {/*Features*/}
                  {/*Theme Elements*/}
                  {/*                  <Route path={`${process.env.PUBLIC_URL}/features/element-title`} component={ElementTitle}/>
                  <Route path={`${process.env.PUBLIC_URL}/features/element-banner`} component={ElementBanner}/>
                  <Route path={`${process.env.PUBLIC_URL}/features/element-slider`} component={ElementSlider}/>
                  <Route path={`${process.env.PUBLIC_URL}/features/element-category`} component={ElementCategory}/>
                  <Route path={`${process.env.PUBLIC_URL}/features/element-service`} component={ElementService}/>
                  <Route path={`${process.env.PUBLIC_URL}/features/element-ratio`} component={ElementRatio}/>*/}

                  {/*Product Elements*/}
                  {/*  <Route path={`${process.env.PUBLIC_URL}/features/element-product-box`} component={ElementProductBox}/>
                  <Route path={`${process.env.PUBLIC_URL}/features/element-product-slider`}
                         component={ElementProductSlider}/>
                  <Route path={`${process.env.PUBLIC_URL}/features/element-product-no-slider`}
                         component={ElementProductNoSlider}/>
                  <Route path={`${process.env.PUBLIC_URL}/features/element-product-multiple-slider`}
                         component={ElementMultipleSlider}/>
                  <Route path={`${process.env.PUBLIC_URL}/features/element-product-tab`} component={ElementProductTab}/>
*/}
                  {/*Portfolios*/}
                  {/*     <Route path={`${process.env.PUBLIC_URL}/features/portfolio-grid/:columns`} component={GridCols}/>
                  <Route path={`${process.env.PUBLIC_URL}/features/portfolio-masonary/:columns`}
                         component={MasonaryGridCols}/>
*/}
                  {/*Blog Pages*/}
                  {/*        <Route path={`${process.env.PUBLIC_URL}/blog/right-sidebar`} component={RightSide}/>
                  <Route path={`${process.env.PUBLIC_URL}/blog/details`} component={Details}/>
                  <Route path={`${process.env.PUBLIC_URL}/blog/blog-page`} component={BlogPage}/>*/}

                  {/*<Route exact path="*" component={PageNotFound} />*/}
                </Layout>
              </Switch>
            </ScrollContext>
          </BrowserRouter>
        </IntlProvider>
      </Provider>
    );
  }
}

ReactDOM.render(<Root/>, document.getElementById('root'));


