import React, {Component} from 'react';
import * as qs from "query-string";
import {handlePaymentResponse} from "../services/api";
import {toast} from "react-toastify";
import {clearCart} from "../../actions";
import {config} from "../config";
import store from "../../store";
import Link from "react-router-dom/Link";


class orderSuccess extends Component {

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      loading: false,
      paymentId: '',
      orderId: '',
      clientInfo: {},
      shippingPrice: 0,
      subTotal: 0,
      date: null,
      location: "OTHER",
      shippingMethod: "HAY_POST"
    }
  }

  componentDidMount() {
    const {location: {search}} = this.props;
    const queries = qs.parse(search, {ignoreQueryPrefix: true});

    const location = window.location.href;

    function validateQueryParams(queries) {
      return queries.orderID !== undefined
        && queries.resposneCode !== undefined
        && queries.paymentID !== undefined
        && queries.opaque !== undefined
        && queries.description !== undefined;
    }

    if (validateQueryParams(queries)) {
      const payment = {
        orderId: queries.orderID,
        responseCode: queries.resposneCode,
        paymentId: queries.paymentID,
        opaque: queries.opaque,
        description: queries.description,
        url: location,
      };

      handlePaymentResponse(payment)
        .then((response) => {
          this.setState({
            items: response.products,
            clientInfo: response.clientInfo,
            date: response.date,
            expectedDeliveryDate: response.expectedDeliveryDate,
            loading: false,
            paymentId: payment.paymentId,
            orderId: payment.orderId,
            subTotal: response.subTotal,
            shippingPrice: response.shippingPrice,
            location: response.location,
            shippingMethod: response.shippingMethod
          });
          if (!queries.fromLink) {
            store.dispatch(clearCart());
          }
        })
        .catch((error) => {
          this.props.history.push({
            pathname: '/cart',
          });
          toast.error("Payment failed. Please try again or contact us.");
        });

    } else {
      this.props.history.push({
        pathname: '/not-found',
      })
    }
  }


  render() {
    const {items, clientInfo, date, expectedDeliveryDate, shippingMethod, orderId, subTotal, shippingPrice, location} = this.state;
    const isMoreThan2KG = false;
    var options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
    const formatedDate = new Date(date).toLocaleTimeString("en-US", options).toString();
    let deliveryDate;
    if (expectedDeliveryDate) {
      deliveryDate = new Date(expectedDeliveryDate).toLocaleDateString("en-US", options).toString();
    } else {
      deliveryDate = (new Date(Date.now() + 20 * 86400000)).toLocaleDateString("en-US", options).toString();
    }
    const payment = {
      id: 1
    };

    function getCurrencyFromLocation(location) {
        switch (location) {
            case "ARMENIA":
                return "֏";
            case "RUSSIA":
                return "₽";
            case "EUROPE":
                return "€";
            case "OTHER":
            default:
                return "$";
        }
    }

    const symbol = getCurrencyFromLocation(location);

    function getShippingUrl(shippingMethod) {
      if (shippingMethod === "HAY_POST") {
        return "https://www.haypost.am/en";
      } else if (shippingMethod === "ALADIN") {
        return "https://www.facebook.com/llc.aladin.express";
      } else if (shippingMethod === "GLOBBING") {
        return "https://globbing.com/";
      } else if (shippingMethod === "GG") {
        return "https://www.facebook.com/ggTaxi/";
      } else if (shippingMethod === "AEKS") {
        return "https://www.aekshipping.com/";
      } else {
        return "https://lightaffect.am";
      }
    }
    function getShippingMethod(shippingMethod) {
      if (shippingMethod === "HAY_POST") {
        return "Hay Post";
      } else if (shippingMethod === "HAY_POST_EMS") {
        return "Hay Post EMS";
      } else if (shippingMethod === "ALADIN") {
        return "Aladin";
      } else if (shippingMethod === "GLOBBING") {
        return "Globbing";
      } else if (shippingMethod === "GG") {
        return "GG";
      } else
        return shippingMethod;
    }

    return (
      (payment) ?
        <div>
          <section className="light-layout">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="success-text">
                    <i className="fa fa-check-circle" aria-hidden="true"/>
                    <h2>thank you</h2>
                    <p>Order Placed Successfully</p>
                    <p>Order ID: {orderId}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {isMoreThan2KG && <div className="col-md-12">
            <div className="delivery-sec" style={{marginTop: '0'}}>
              <h3>
                Your shipping weight is more than 2kg and will be delivered with separated packages.
              </h3>
            </div>
          </div>
          }
          <div className="col-md-12">
            <div className="delivery-sec" style={{marginTop: '0'}}>
              <h3>expected date of delivery</h3>
              <h2>{deliveryDate}</h2>
            </div>
          </div>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="product-order">
                    <h3>your order details</h3>
                    {items.map((item, index) => {
                      return <div className="row product-order-detail" key={index}>
                        <div className="col-3">
                          <Link to={`/product/${item.productId}`}><img src={item.pictureUrl} alt="" className="img-fluid"/></Link>
                        </div>
                        <div className="col-2 order_detail">
                          <div>
                            <h4>product name</h4>
                            <h5>{item.name}</h5>
                          </div>
                        </div>
                        <div className="col-1 order_detail" style={{marginLeft: '10%'}}>
                          <div>
                            <h4>size</h4>
                            <h5>{item.size}</h5>
                          </div>
                        </div>
                        <div className="col-1 order_detail" style={{marginLeft: '7%'}}>
                          <div>
                            <h4>quantity</h4>
                            <h5>{item.qty}</h5>
                          </div>
                        </div>
                        <div className="col-1 order_detail" style={{marginLeft: '10%'}}>
                          <div>
                            <h4>price</h4>
                            <h5>{item.priceMap[this.state.location]} {symbol}</h5>
                          </div>
                        </div>
                      </div>
                    })}
                    <div className="total-sec">
                      <ul>
                        <li>subtotal <span>{symbol}{subTotal}</span></li>
                        <li>shipping <span>{symbol}{shippingPrice}</span></li>
                      </ul>
                    </div>
                    <div className="final-total">
                      <h3>total <span>{symbol}{subTotal + shippingPrice}</span></h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" style={{margin: '5% 0'}}>
                <div className="col-md-12">
                  <div className="row order-success-sec">
                    <div className="col-sm-6">
                      <h4>summery</h4>
                      <ul className="order-detail">
                        <li>Order ID: {orderId}</li>

                        <li>Order Date: {formatedDate}</li>
                        <li>Order Total: {symbol}{subTotal + shippingPrice}</li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <h4>shipping address</h4>
                      <ul className="order-detail">
                        <li style={{textTransform: 'initial'}}>{clientInfo.email}</li>
                        <li style={{textTransform: 'initial'}}>{clientInfo.firstName} {clientInfo.lastName}</li>
                        <li style={{textTransform: 'initial'}}>{clientInfo.country} {clientInfo.city}</li>
                        <li style={{textTransform: 'initial'}}>{clientInfo.address}</li>
                        <li style={{textTransform: 'initial'}}>{clientInfo.postalCode}</li>
                        <li style={{textTransform: 'initial'}}>Phone: {clientInfo.phone}</li>
                        <li style={{textTransform: 'initial'}}>Method:  <a href={getShippingUrl(shippingMethod)} target="_blank"
                                                                          style={{textDecoration: 'none'}}>&nbsp; {getShippingMethod(shippingMethod)}</a></li>
                      </ul>
                    </div>

                    <div className="col-sm-12 payment-mode">
                      <h4>payment method</h4>
                      <p>Online Payment</p>
                    </div>
                  </div>
                  {
                    clientInfo.country === 'Armenia - YEREVAN' ?
                    <div style={{color: 'grey', paddingTop: '3%'}}>**Return or exchange is possible during 14 days after
                    the purchase has been made. The delivery fee is not being returned in case of a return or
                    exchange. </div>
                        :
                        <div/>
                  }
                </div>
              </div>

            </div>
          </section>
        </div>
        :
        <section className="p-0">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="error-section">
                  <h1>404</h1>
                  <h2>page not found</h2>
                  <a href="index.html" className="btn btn-solid">back to home</a>
                </div>
              </div>
            </div>
          </div>
        </section>
    )
  }
}

export default orderSuccess
